import React from 'react';
import st from './LandingFooter.module.scss';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import { ReactComponent as Logo } from '../../assets/images/icons/logo.svg';
import { ReactComponent as FacebookLogo} from '../../assets/images/icons/social_fb.svg';
import { ReactComponent as InstagramLogo} from '../../assets/images/icons/social_instagram.svg';
import { ReactComponent as TwitterLogo} from '../../assets/images/icons/social_twitter.svg';
import { ReactComponent as BmbLogo } from '../../assets/images/icons/bmb_logo_sd.svg';
import { NonAuthRoutes } from '../../routes/routes';
import UIStore from '../../store/UIStore';
import {useIntl} from "react-intl";


const LandingFooter = observer(() => {
    const intl = useIntl();
    const { localeUrl } = UIStore;
    const year = new Date().getFullYear();

    return (
        <footer className={st.footer}>
            <div className={st.container}>
                <div className={st.footer_info}>
                    <div className={st.logo}>
                        <Link to={localeUrl(NonAuthRoutes.home)}>
                            <Logo color="light" />
                        </Link>
                    </div>
                    <nav className={st.nav}>
                        <ul className={st.links}>
                            {/* <li>
                                <Link to="/how" className={st.link}>
                                    {intl.formatMessage({id: "footer.works"})}
                                </Link>
                            </li> */}
                            <li>
                                <Link to="/for-employers" className={st.link}>
                                    {intl.formatMessage({id: "footer.employers"})}
                                </Link>
                            </li>
{/*                            <li>
                                <Link to={NonAuthRoutes.experts} className={st.link}>
                                    {intl.formatMessage({id: "app.header.experts"})}
                                </Link>
                            </li>*/}
                            <li>
                                <Link to="/faq" className={st.link}>
                                    {intl.formatMessage({id: "faq"})}
                                </Link>
                            </li>
                            <li>
                                <Link to="/blog" className={st.link}>
                                    {intl.formatMessage({id: "blog"})}
                                </Link>
                            </li>
                            <li>
                                <Link to="/contact" className={st.link}>
                                    {intl.formatMessage({id: "app.header.menu.contacts"})}
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <div className={st.socials}>
                        <Link to="/#" className={st.social}>
                            <FacebookLogo />
                        </Link>
                        <Link to="/#" className={st.social}>
                            <InstagramLogo />
                        </Link>
                        <Link to="/#" className={st.social}>
                            <TwitterLogo />
                        </Link>
                    </div>
                </div>
            </div>
            <div className={st.container}>
                <div className={st.copy_wrap}>
                    <div className={st.copy}>
                        &copy; <p>{year}</p> <span>|</span> FseXpert <span>|</span> {intl.formatMessage({id: "footer.rights"})}
                    </div>
                    <div className={st.copy_links}>
                        <Link to={localeUrl(NonAuthRoutes.privacyPolicy)} className={st.copy_link}>
                            {intl.formatMessage({id: "auth.signup.terms.privacy"})}
                        </Link>
                        <Link to={localeUrl(NonAuthRoutes.termsOfUse)} className={st.copy_link}>
                            {intl.formatMessage({id: "footer.terms"})}
                        </Link>
                    </div>
                    <div className={st.developer}>
                        {intl.formatMessage({id: "footer.website"})}{' '}
                        <a href="https://brandmybrand.io/">
                            <BmbLogo />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
});

export default LandingFooter;
